.c-app {
    @include theme-variant(true) {
      --#{$color}: #{$value};
    }
    @include themes($body-theme-map, $create: append) {
      color: themes-get-value("body-color");
      background-color: themes-get-value("body-bg");
      --color: #205FAD;
    }
  }
.c-sidebar{
  background-color: #205FAD;
}
.c-sidebar .c-sidebar-nav-title {
  color: #fbb040;
  padding: 0.5rem 1rem;
  text-transform: none;
  font-size: 100%;
  margin: 0;
}
.c-sidebar .c-sidebar-nav-link.c-active {
  background: rgba(255, 255, 255, 0.1);
  border-right: 5px solid #fbb040;
}

.c-main{
  padding: 0;
}
  // Here you can add other styles

.page-body {
  height: calc(100vh - 170px);
  display: flex;
  flex-direction: column;
  border-top: 2px solid #ebedef;
  overflow-x: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}
