/*------------------------------------------------------------
|  Author: Free-Template.co                                   
|  Author URL: https://free-template.co/                      
|  License: https://creativecommons.org/licenses/by/3.0/      
|  License URL: https://creativecommons.org/licenses/by/3.0/  
-------------------------------------------------------------*/
/* Base */
/* body {
  line-height: 1.7;
  color: gray;
  font-weight: 300;
  font-size: 1.1rem; } */

::-moz-selection {
  background: #000;
  color: #fff;
}

::selection {
  background: #000;
  color: #fff;
}

a {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
a:hover {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
}

.border-2 {
  border-width: 2px;
}

.text-black {
  color: #000 !important;
}

.bg-black {
  background: #000 !important;
}

.color-black-opacity-5 {
  color: rgba(0, 0, 0, 0.5);
}

.color-white-opacity-5 {
  color: rgba(255, 255, 255, 0.5);
}

.site-wrap:before {
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  background: rgba(0, 0, 0, 0.6);
  content: "";
  position: absolute;
  z-index: 2000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
}

.offcanvas-menu .site-wrap {
  height: 100%;
  width: 100%;
  z-index: 2;
  overflow: hidden;
}
.offcanvas-menu .site-wrap:before {
  opacity: 1;
  visibility: visible;
}

.offcanvas-menu {
  position: relative;
}
.offcanvas-menu:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  top: 0;
  right: 0;
  z-index: 21;
  background: rgba(0, 0, 0, 0.2);
}

.btn {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 900;
}
.btn:hover,
.btn:active,
.btn:focus {
  outline: none;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.btn.btn-black {
  border-width: 2px;
  border-color: #000;
  background: #000;
  color: #fff;
}
.btn.btn-black:hover {
  color: #000;
  background-color: transparent;
}
.btn.btn-black.btn-outline-black {
  color: #000;
  background-color: transparent;
}
.btn.btn-black.btn-outline-black:hover {
  border-color: #000;
  background: #000;
  color: #fff;
}
.btn.btn-white {
  border-width: 2px;
  border-color: #fff;
  background: #fff;
  color: #000;
}
.btn.btn-white:hover {
  color: #fff;
  background-color: transparent;
}
.btn.btn-white.btn-outline-white {
  color: #fff;
  background-color: transparent;
}
.btn.btn-white.btn-outline-white:hover {
  border-color: #fff;
  background: #fff;
  color: #000;
}

.line-height-1 {
  line-height: 1 !important;
}

.bg-black {
  background: #000;
}

.form-control {
  height: 55px;
  border-width: 2px;
  font-family: "Muli", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.form-control:active,
.form-control:focus {
  border-color: #205fad;
}
.form-control:hover,
.form-control:active,
.form-control:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.site-section {
  margin-top: 50px;
  padding: 2.5em 0;
}
@media (min-width: 768px) {
  .site-section {
    padding: 5em 0;
  }
}
.site-section.site-section-sm {
  padding: 4em 0;
}

.site-section-heading {
  padding-bottom: 20px;
  margin-bottom: 0px;
  position: relative;
  font-size: 2.5rem;
}
@media (min-width: 768px) {
  .site-section-heading {
    font-size: 3rem;
  }
}

.site-footer {
  padding: 4em 0;
  background: #333333;
}
@media (min-width: 768px) {
  .site-footer {
    padding: 8em 0;
  }
}
.site-footer .border-top {
  border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.site-footer p {
  color: #737373;
}
.site-footer h2,
.site-footer h3,
.site-footer h4,
.site-footer h5 {
  color: #000;
}
.site-footer a {
  color: #999999;
}
.site-footer a:hover {
  color: black;
}
.site-footer ul li {
  margin-bottom: 10px;
}
.site-footer .footer-heading {
  font-size: 16px;
  color: #000;
  text-transform: uppercase;
  font-weight: 900;
}

.bg-text-line {
  display: inline;
  background: #000;
  -webkit-box-shadow: 20px 0 0 #000, -20px 0 0 #000;
  box-shadow: 20px 0 0 #000, -20px 0 0 #000;
}

.text-white-opacity-05 {
  color: rgba(255, 255, 255, 0.5);
}

.text-black-opacity-05 {
  color: rgba(0, 0, 0, 0.5);
}

.hover-bg-enlarge {
  overflow: hidden;
  position: relative;
}
@media (max-width: 991.98px) {
  .hover-bg-enlarge {
    height: auto !important;
  }
}
.hover-bg-enlarge > div {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.8s all ease-in-out;
  -o-transition: 0.8s all ease-in-out;
  transition: 0.8s all ease-in-out;
}
.hover-bg-enlarge:hover > div,
.hover-bg-enlarge:focus > div,
.hover-bg-enlarge:active > div {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
@media (max-width: 991.98px) {
  .hover-bg-enlarge .bg-image-md-height {
    height: 300px !important;
  }
}

.bg-image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.bg-image.overlay {
  position: relative;
}
.bg-image.overlay:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
}
.bg-image > .container {
  position: relative;
  z-index: 1;
}

@media (max-width: 991.98px) {
  .img-md-fluid {
    max-width: 100%;
  }
}

@media (max-width: 991.98px) {
  .display-1,
  .display-3 {
    font-size: 3rem;
  }
}

.play-single-big {
  width: 90px;
  height: 90px;
  display: inline-block;
  border: 2px solid #fff;
  color: #fff !important;
  border-radius: 50%;
  position: relative;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.play-single-big > span {
  font-size: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-40%, -50%);
  -ms-transform: translate(-40%, -50%);
  transform: translate(-40%, -50%);
}
.play-single-big:hover {
  width: 120px;
  height: 120px;
}

.overlap-to-top {
  margin-top: -150px;
}

.ul-check {
  margin-bottom: 50px;
}
.ul-check li {
  position: relative;
  padding-left: 35px;
  margin-bottom: 15px;
  line-height: 1.5;
}
.ul-check li:before {
  left: 0;
  font-size: 20px;
  top: -0.3rem;
  font-family: "icomoon";
  content: "\e5ca";
  position: absolute;
}
.ul-check.white li:before {
  color: #fff;
}
.ul-check.success li:before {
  color: #28a745;
}
.ul-check.primary li:before {
  color: #205fad;
}

.select-wrap,
.wrap-icon {
  position: relative;
}
.select-wrap .icon,
.wrap-icon .icon {
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 22px;
}
.select-wrap select,
.wrap-icon select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
}

/* Navbar */
.site-logo {
  position: relative;
  font-weight: 900;
  font-size: 1.3rem;
  font-family: "Oswald", sans-serif;
  letter-spacing: 0.2rem;
}
.site-logo a {
  color: #fff;
}

.site-navbar {
  margin-bottom: 0px;
  z-index: 1999;
  position: absolute;
  width: 100%;
}
.site-navbar .container-fluid {
  padding-left: 7rem;
  padding-right: 7rem;
}
@media (max-width: 1199.98px) {
  .site-navbar .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.site-navbar .site-navigation .site-menu {
  margin-bottom: 0;
}
.site-navbar .site-navigation .site-menu .active {
  color: #205fad;
  display: inline-block;
  padding: 5px 20px;
}
.site-navbar .site-navigation .site-menu .cta a:before,
.site-navbar .site-navigation .site-menu .cta a:after {
  display: none;
}
.site-navbar .site-navigation .site-menu a {
  text-decoration: none !important;
  display: inline-block;
}
.site-navbar .site-navigation .site-menu > li {
  display: inline-block;
}
.site-navbar .site-navigation .site-menu > li > a {
  padding: 5px 20px;
  color: #fff;
  display: inline-block;
  text-decoration: none !important;
}
.site-navbar .site-navigation .site-menu > li > a:hover {
  color: #fff;
}
.site-navbar .site-navigation .site-menu .has-children {
  position: relative;
}
.site-navbar .site-navigation .site-menu .has-children > a {
  position: relative;
  padding-right: 20px;
}
.site-navbar .site-navigation .site-menu .has-children > a:before {
  position: absolute;
  content: "\e313";
  font-size: 16px;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: "icomoon";
}
.site-navbar .site-navigation .site-menu .has-children .dropdown {
  visibility: hidden;
  opacity: 0;
  top: 100%;
  position: absolute;
  text-align: left;
  border-top: 2px solid #205fad;
  -webkit-box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.1);
  padding: 0px 0;
  margin-top: 20px;
  margin-left: 0px;
  background: #fff;
  -webkit-transition: 0.2s 0s;
  -o-transition: 0.2s 0s;
  transition: 0.2s 0s;
}
.site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top {
  position: absolute;
}
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown.arrow-top:before {
  bottom: 100%;
  left: 20%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown.arrow-top:before {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}
.site-navbar .site-navigation .site-menu .has-children .dropdown a {
  text-transform: none;
  letter-spacing: normal;
  -webkit-transition: 0s all;
  -o-transition: 0s all;
  transition: 0s all;
  color: #343a40;
}
.site-navbar .site-navigation .site-menu .has-children .dropdown .active > a {
  color: #205fad !important;
}
.site-navbar .site-navigation .site-menu .has-children .dropdown > li {
  list-style: none;
  padding: 0;
  margin: 0;
  min-width: 200px;
}
.site-navbar .site-navigation .site-menu .has-children .dropdown > li > a {
  padding: 9px 20px;
  display: block;
}
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li
  > a:hover {
  background: #ebeef0;
  color: #212529;
}
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children
  > a:before {
  content: "\e315";
  right: 20px;
}
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children
  > .dropdown,
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children
  > ul {
  left: 100%;
  top: 0;
}
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children:hover
  > a,
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children:active
  > a,
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children:focus
  > a {
  background: #ebeef0;
  color: #212529;
}
.site-navbar .site-navigation .site-menu .has-children:hover > a,
.site-navbar .site-navigation .site-menu .has-children:focus > a,
.site-navbar .site-navigation .site-menu .has-children:active > a {
  color: #205fad;
}
.site-navbar .site-navigation .site-menu .has-children:hover,
.site-navbar .site-navigation .site-menu .has-children:focus,
.site-navbar .site-navigation .site-menu .has-children:active {
  cursor: pointer;
}
.site-navbar .site-navigation .site-menu .has-children:hover > .dropdown,
.site-navbar .site-navigation .site-menu .has-children:focus > .dropdown,
.site-navbar .site-navigation .site-menu .has-children:active > .dropdown {
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
  margin-top: 0px;
  visibility: visible;
  opacity: 1;
}
.site-navbar .site-navigation .site-menu.site-menu-dark > li > a {
  color: black;
  position: relative;
}
.site-navbar .site-navigation .site-menu.site-menu-dark > li > a:after {
  height: 2px;
  background: #000;
  content: "";
  position: absolute;
  bottom: 0;
  left: 20px;
  right: 20px;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.site-navbar .site-navigation .site-menu.site-menu-dark > li > a:hover,
.site-navbar .site-navigation .site-menu.site-menu-dark > li > a.active {
  color: #205fad;
  position: relative;
}
.site-navbar .site-navigation .site-menu.site-menu-dark > li > a:hover:after,
.site-navbar .site-navigation .site-menu.site-menu-dark > li > a.active:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.site-mobile-menu {
  width: 300px;
  position: fixed;
  right: 0;
  z-index: 2000;
  padding-top: 20px;
  background: #fff;
  height: calc(100vh);
  -webkit-transform: translateX(110%);
  -ms-transform: translateX(110%);
  transform: translateX(110%);
  -webkit-box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
  box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.offcanvas-menu .site-mobile-menu {
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}
.site-mobile-menu .site-mobile-menu-header {
  width: 100%;
  float: left;
  padding-left: 20px;
  padding-right: 20px;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close {
  float: right;
  margin-top: 8px;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span {
  font-size: 30px;
  display: inline-block;
  padding-left: 10px;
  padding-right: 0px;
  line-height: 1;
  cursor: pointer;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span:hover {
  color: #dee2e6;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo {
  float: left;
  margin-top: 10px;
  margin-left: 0px;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a {
  display: inline-block;
  text-transform: uppercase;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a img {
  max-width: 70px;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a:hover {
  text-decoration: none;
}
.site-mobile-menu .site-mobile-menu-body {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: relative;
  padding: 0 20px 20px 20px;
  height: calc(100vh - 52px);
  padding-bottom: 150px;
}
.site-mobile-menu .site-nav-wrap {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}
.site-mobile-menu .site-nav-wrap a {
  padding: 10px 20px;
  display: block;
  position: relative;
  color: #212529;
}
.site-mobile-menu .site-nav-wrap a:hover {
  color: #205fad;
}
.site-mobile-menu .site-nav-wrap li {
  position: relative;
  display: block;
}
.site-mobile-menu .site-nav-wrap li .active {
  color: #dc3545;
}
.site-mobile-menu .site-nav-wrap .arrow-collapse {
  position: absolute;
  right: 0px;
  top: 10px;
  z-index: 20;
  width: 36px;
  height: 36px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
}
.site-mobile-menu .site-nav-wrap .arrow-collapse:hover {
  background: #f8f9fa;
}
.site-mobile-menu .site-nav-wrap .arrow-collapse:before {
  font-size: 12px;
  z-index: 20;
  font-family: "icomoon";
  content: "\f078";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-180deg);
  -ms-transform: translate(-50%, -50%) rotate(-180deg);
  transform: translate(-50%, -50%) rotate(-180deg);
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.site-mobile-menu .site-nav-wrap .arrow-collapse.collapsed:before {
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.site-mobile-menu .site-nav-wrap > li {
  display: block;
  position: relative;
  float: left;
  width: 100%;
}
.site-mobile-menu .site-nav-wrap > li > a {
  padding-left: 20px;
  font-size: 20px;
}
.site-mobile-menu .site-nav-wrap > li > ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.site-mobile-menu .site-nav-wrap > li > ul > li {
  display: block;
}
.site-mobile-menu .site-nav-wrap > li > ul > li > a {
  padding-left: 40px;
  font-size: 16px;
}
.site-mobile-menu .site-nav-wrap > li > ul > li > ul {
  padding: 0;
  margin: 0;
}
.site-mobile-menu .site-nav-wrap > li > ul > li > ul > li {
  display: block;
}
.site-mobile-menu .site-nav-wrap > li > ul > li > ul > li > a {
  font-size: 16px;
  padding-left: 60px;
}
.site-mobile-menu .site-nav-wrap[data-class="social"] {
  float: left;
  width: 100%;
  margin-top: 30px;
  padding-bottom: 5em;
}
.site-mobile-menu .site-nav-wrap[data-class="social"] > li {
  width: auto;
}
.site-mobile-menu .site-nav-wrap[data-class="social"] > li:first-child a {
  padding-left: 15px !important;
}

.sticky-wrapper {
  position: absolute;
  z-index: 100;
  width: 100%;
}
.sticky-wrapper + .site-blocks-cover {
  margin-top: 96px;
}
.sticky-wrapper .site-menu-toggle {
  color: #000;
}
@media (max-width: 991.98px) {
  .sticky-wrapper .site-menu-toggle {
    color: #fff;
  }
}
.sticky-wrapper .site-navbar {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.sticky-wrapper .site-navbar .site-menu > li {
  display: inline-block;
}
.sticky-wrapper .site-navbar .site-menu > li > a {
  position: relative;
}
.sticky-wrapper .site-navbar .site-menu > li > a:after {
  height: 2px;
  background: #fff;
  content: "";
  position: absolute;
  bottom: 0;
  left: 20px;
  right: 20px;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.sticky-wrapper .site-navbar .site-menu > li > a:hover,
.sticky-wrapper .site-navbar .site-menu > li > a.active {
  color: #fff;
  position: relative;
}
.sticky-wrapper .site-navbar .site-menu > li > a:hover:after,
.sticky-wrapper .site-navbar .site-menu > li > a.active:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.sticky-wrapper.is-sticky .site-menu-toggle {
  color: #000;
}
.sticky-wrapper.is-sticky .site-navbar {
  -webkit-box-shadow: 4px 0 20px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 4px 0 20px -5px rgba(0, 0, 0, 0.2);
  background: #fff;
}
.sticky-wrapper.is-sticky .site-navbar .site-logo a {
  color: #000;
}
.sticky-wrapper.is-sticky .site-navbar .site-menu > li {
  display: inline-block;
}
.sticky-wrapper.is-sticky .site-navbar .site-menu > li > a {
  position: relative;
  color: #000;
}
.sticky-wrapper.is-sticky .site-navbar .site-menu > li > a:after {
  height: 2px;
  background: #205fad;
  content: "";
  position: absolute;
  bottom: 0;
  left: 20px;
  right: 20px;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.sticky-wrapper.is-sticky .site-navbar .site-menu > li > a:hover,
.sticky-wrapper.is-sticky .site-navbar .site-menu > li > a.active {
  color: #205fad;
  position: relative;
}
.sticky-wrapper.is-sticky .site-navbar .site-menu > li > a:hover:after,
.sticky-wrapper.is-sticky .site-navbar .site-menu > li > a.active:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.sticky-wrapper .shrink {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

/* Blocks */
.gradient-bg {
  background: #8186d5;
  background: -moz-linear-gradient(-45deg, #8186d5 0%, #494ca2 100%);
  background: -webkit-gradient(
    left top,
    right bottom,
    color-stop(0%, #8186d5),
    color-stop(100%, #494ca2)
  );
  background: -webkit-linear-gradient(-45deg, #8186d5 0%, #494ca2 100%);
  background: -o-linear-gradient(-45deg, #8186d5 0%, #494ca2 100%);
  background: -ms-linear-gradient(-45deg, #8186d5 0%, #494ca2 100%);
  background: -webkit-linear-gradient(315deg, #8186d5 0%, #494ca2 100%);
  background: -o-linear-gradient(315deg, #8186d5 0%, #494ca2 100%);
  background: linear-gradient(135deg, #8186d5 0%, #494ca2 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8186d5', endColorstr='#494ca2', GradientType=1 );
}

.intro-section {
  position: relative;
}
.intro-section,
.intro-section .container .row {
  height: 100vh;
  min-height: 1000px;
}
.intro-section:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 70%;
  background: #205fad;
  border-bottom-right-radius: 200px;
}
@media (max-width: 991.98px) {
  .intro-section:before {
    width: 100%;
  }
}
.intro-section h1 {
  font-size: 3rem;
  font-weight: 900;
  color: #fff;
}
.intro-section p {
  color: rgba(255, 255, 255, 0.87);
  font-size: 1.1rem;
  max-width: 400px;
}
@media (max-width: 991.98px) {
  .intro-section p {
    min-width: 100%;
  }
}
.intro-section .btn {
  border-width: 2px;
}

.site-section {
  padding: 10em 0;
}
@media (max-width: 991.98px) {
  .site-section {
    padding: 4em 0;
  }
}

.cta a span {
  border-color: #205fad;
  padding: 10px 20px;
}

.cta a:hover span {
  background: #007bff;
  color: #fff;
}

.cta a.active {
  border-bottom: none;
}
.cta a.active > span {
  background-color: #007bff;
  color: #fff;
}

.bg-light {
  background: #f1f3f8 !important;
}

.section-sub-title {
  color: #205fad;
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 900;
  display: block;
  margin-bottom: 1.5rem;
}

.section-title {
  font-family: Oswald;
  font-size: 3rem;
  color: #205fad;
  margin-bottom: 1.8rem;
  font-weight: 900;
  max-width: 450px;
}
@media (max-width: 991.98px) {
  .section-title {
    font-size: 2.5rem;
  }
}

.counter .icon-wrap {
  margin-right: 1.5rem;
}
.counter .icon-wrap > span {
  position: relative;
  top: -0.2em;
  font-size: 2rem;
}

.counter .counter-text strong {
  line-height: 0.5;
  font-size: 3.5rem;
  display: block;
  font-weight: 700;
  color: #000;
  margin-bottom: 1rem;
}

.counter .counter-text span {
  font-size: 1rem;
  line-height: 1;
}

.image-absolute-box {
  position: relative;
}
.image-absolute-box .box {
  position: absolute;
  background: #205fad;
  padding: 30px;
  max-width: 300px;
  bottom: -20%;
  right: -20%;
}
@media (max-width: 991.98px) {
  .image-absolute-box .box {
    top: auto;
    right: auto;
    position: relative;
    width: 100%;
    max-width: 100%;
  }
}
.image-absolute-box .box .icon-wrap {
  color: #fff;
  margin-bottom: 1.5rem;
}
.image-absolute-box .box .icon-wrap span {
  font-size: 2rem;
}
.image-absolute-box .box h3 {
  font-size: 1.2rem;
  color: #fff;
  margin-bottom: 1.5rem;
}
.image-absolute-box .box p {
  color: rgba(255, 255, 255, 0.55);
}

.section-1 {
  margin-top: 10rem;
}

.work-thumb {
  position: relative;
  display: block;
  cursor: pointer;
  -webkit-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
}
.work-thumb:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background: rgba(53, 41, 97, 0.8);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.work-thumb .work-text {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  margin-top: 20px;
}
.work-thumb .work-text h3 {
  font-size: 1.5rem;
  font-weight: 900;
  color: #fff;
  margin: 0;
  padding: 0;
}
.work-thumb .work-text .category {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
}
.work-thumb:hover {
  z-index: 3;
  -webkit-transform: scale(1.03);
  -ms-transform: scale(1.03);
  transform: scale(1.03);
}
.work-thumb:hover:after {
  opacity: 0.9;
  visibility: visible;
}
.work-thumb:hover .work-text {
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
}

.nonloop-block-13 .owl-nav {
  display: none;
}

.nonloop-block-13 .owl-dots {
  margin-top: 30px;
  width: 100%;
  text-align: center;
}
.nonloop-block-13 .owl-dots .owl-dot {
  display: inline-block;
  margin: 5px;
}
.nonloop-block-13 .owl-dots .owl-dot span {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #e6e6e6;
}
.nonloop-block-13 .owl-dots .owl-dot.active span {
  background-color: #205fad;
}

.nonloop-block-13 .owl-stage {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.nonloop-block-13 .owl-item {
  position: relative;
  z-index: 2;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.1s all cubic-bezier(0.17, 0.67, 0.86, 0.2);
  -o-transition: 0.1s all cubic-bezier(0.17, 0.67, 0.86, 0.2);
  transition: 0.1s all cubic-bezier(0.17, 0.67, 0.86, 0.2);
}
.nonloop-block-13 .owl-item:hover {
  z-index: 3;
  -webkit-transform: scale(1.07);
  -ms-transform: scale(1.07);
  transform: scale(1.07);
}

.nonloop-block-14 .owl-nav {
  display: none;
}

.nonloop-block-14 .owl-dots {
  margin-top: 30px;
  width: 100%;
  text-align: center;
}
.nonloop-block-14 .owl-dots .owl-dot {
  display: inline-block;
  margin: 5px;
}
.nonloop-block-14 .owl-dots .owl-dot span {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
}
.nonloop-block-14 .owl-dots .owl-dot.active span {
  background-color: #205fad;
}

.footer-section {
  padding: 7em 0;
}
.footer-section p {
  font-size: 1rem;
}
.footer-section h3 {
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 1.5rem;
  font-weight: 900;
}
.footer-section .footer-links li {
  margin-bottom: 10px;
}

.process,
.service {
  position: relative;
}
.process .number,
.service .number {
  position: absolute;
  top: 50px;
  left: -30px;
  line-height: 0;
  z-index: -1;
  font-size: 10rem;
  color: whitesmoke;
  font-weight: 900;
}
.process h3,
.service h3 {
  font-size: 1.5rem;
  color: #205fad;
  font-weight: 700;
  margin-bottom: 1.5rem;
  margin-top: 2rem;
}

.process {
  text-align: center;
  padding: 30px;
  height: 400px;
  border: 1px solid #d9d9d9;
}

.service {
  padding: 4rem;
  background: #fff;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  top: 0;
  -webkit-transition: 0.3s all cubic-bezier(0.17, 0.67, 0.52, 0.94);
  -o-transition: 0.3s all cubic-bezier(0.17, 0.67, 0.52, 0.94);
  transition: 0.3s all cubic-bezier(0.17, 0.67, 0.52, 0.94);
}
@media (max-width: 991.98px) {
  .service {
    padding: 2rem;
  }
}
.service .custom-icon-wrap {
  width: 80px;
  height: 80px;
  display: block;
  border-radius: 50%;
  background-color: #fafafa;
  position: relative;
  margin-bottom: 1.5rem;
  -webkit-transition: 0.2s all cubic-bezier(0.17, 0.67, 0.52, 0.94);
  -o-transition: 0.2s all cubic-bezier(0.17, 0.67, 0.52, 0.94);
  transition: 0.2s all cubic-bezier(0.17, 0.67, 0.52, 0.94);
  top: 0;
}
.service .custom-icon-wrap > span {
  font-size: 1.8rem;
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 50%;
  color: #dc3545;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.service:before,
.service:after {
  position: absolute;
  content: "";
  height: 7px;
  background: #205fad;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-transition: 0.3s all cubic-bezier(0.17, 0.67, 0.52, 0.94);
  -o-transition: 0.3s all cubic-bezier(0.17, 0.67, 0.52, 0.94);
  transition: 0.3s all cubic-bezier(0.17, 0.67, 0.52, 0.94);
}
.service:before {
  z-index: 1;
}
.service:after {
  background: #dc3545;
  width: 0;
  z-index: 2;
}
.service:hover {
  top: -10px;
  -webkit-box-shadow: 0 4px 30px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 30px -5px rgba(0, 0, 0, 0.2);
}
.service:hover:after {
  width: 100%;
}
.service:hover .custom-icon-wrap {
  background: rgba(220, 53, 69, 0.1);
  top: -10px;
}

.nonloop-block-14 .owl-stage {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.custom-owl-carousel .slide-one-item-alt {
  border-top-left-radius: 200px;
  overflow: hidden;
  -webkit-box-shadow: 0 30px 100px -20px rgba(0, 0, 0, 0.4);
  box-shadow: 0 30px 100px -20px rgba(0, 0, 0, 0.4);
}

@media (max-width: 991.98px) {
  .custom-owl-carousel .col-lg-5 {
    margin-top: 40%;
  }
}

.custom-owl-carousel .owl-custom-direction {
  position: absolute;
  bottom: -20px;
  z-index: 9;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.custom-owl-carousel .owl-custom-direction a {
  z-index: 1;
  display: inline-block;
  padding: 28px 30px;
  position: relative;
  background: #dc3545;
  float: left;
}
.custom-owl-carousel .owl-custom-direction a:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.custom-owl-carousel .owl-custom-direction a:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.custom-owl-carousel .owl-custom-direction a span {
  font-size: 1.5rem;
  display: inline-block;
  position: absolute;
  top: 50%;
  color: #fff;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.custom-owl-carousel .owl-custom-direction a:hover {
  position: relative;
  z-index: 2;
  -webkit-box-shadow: 0 5px 20px -5px rgba(0, 0, 0, 0.4);
  box-shadow: 0 5px 20px -5px rgba(0, 0, 0, 0.4);
}

.testimonial-wrap blockquote p {
  font-size: 1.8rem;
  color: #000;
}
.testimonial-wrap blockquote p.author {
  font-size: 1.2rem;
  font-family: "Oswald", sans-serif;
}

.testimonial-wrap blockquote span {
  display: inline-block;
  font-family: "Oswald", sans-serif;
}

@media (max-width: 991.98px) {
  .slide-one-item-alt-text {
    text-align: center;
  }
}

.pricing-wrap {
  padding-bottom: 20rem;
  padding-top: 7rem;
}
@media (max-width: 1199.98px) {
  .pricing-wrap {
    padding-bottom: 7em;
  }
}
.pricing-wrap .section-desc {
  color: rgba(255, 255, 255, 0.7);
}

.pricing {
  margin-top: -70%;
  background: #fff;
  padding: 30px;
  -webkit-box-shadow: 0 5px 30px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 30px -5px rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  text-align: center;
  z-index: 1;
  position: relative;
}
.pricing em {
  font-style: normal;
}
@media (max-width: 1199.98px) {
  .pricing {
    margin-top: 0;
    margin-bottom: 7rem;
  }
  .pricing:first-child {
    margin-top: -70px;
  }
}
.pricing.pricing-popular {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  z-index: 2;
}
@media (max-width: 1199.98px) {
  .pricing.pricing-popular {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
.pricing h3 {
  color: #205fad;
  font-weight: 300;
  margin-bottom: 1.5rem;
}
.pricing .description,
.pricing .description-2 {
  font-size: 15px;
}
.pricing .price {
  margin: 30px 0;
}
.pricing .price span {
  display: block;
  font-weight: 700;
  font-size: 13px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #205fad;
}
.pricing .price strong {
  font-weight: 300;
  font-family: "Oswald", sans-serif;
  color: #205fad;
  font-size: 4rem;
}
.pricing .btn {
  border-width: 2px;
}

.video-img-bg {
  display: block;
  position: relative;
}
.video-img-bg .play-button {
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #fff;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.video-img-bg .play-button > span {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: 2px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.form-subscribe .btn:hover,
.form-subscribe .btn:active,
.form-subscribe .btn:focus {
  background-color: #205fad !important;
  border-color: #205fad !important;
  color: #fff;
}

/*PRELOADING------------ */
#overlayer {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 7100;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.loader {
  z-index: 7700;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.c-body {
  margin: 16px;
  height: calc(100% - 32px);
}