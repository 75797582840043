body {
  margin: 0;
  font-family: "Muli", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #3c4b64;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

@font-face {
  font-family: Oswald;
  src: url("./assets/font/Oswald-VariableFont_wght.ttf");
}

.App {
  /* text-align: center; */
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

/* transition */
.transition {
  transition: 0.2s;
  -moz-transition: 0.2s;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
}

/* tab */
.tabPanel {
  z-index: -1;
  height: 100%;
}

.dataWrapper {
  position: fixed;
  left: 0%;
  transition: 0.2s;
  -moz-transition: 0.2s;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
}
.hideDrawer {
  left: -20%;
}

.viewDataRoot {
  display: flex;
  flex-direction: row;
  height: 100%;
}
/* tabs */
.makeStyles-defaultTab-3 .MuiTab-wrapper {
  transition: 0.2s;
  -moz-transition: 0.2s;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
}
.makeStyles-defaultTab-3 .MuiTab-wrapper:hover {
  color: #fff;
}
/* page content */
.content {
  text-align: center;
}
/* home page */
.fullpage {
  display: flex;
  justify-content: center;
  height: 100vh;
}
.halfContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow-y: hidden;
  /* flex: 1; */
}
.half {
  display: flex;
  flex: 1;
  width: 50vw;
  justify-content: center;
  align-items: center;
}
.half p {
  text-align: center;
  font-size: 24px;
  line-height: 1.5;
}
/* page stylings */
.topStyles {
  background-image: url(./assets/img/up.svg);
  background-repeat: no-repeat;
  background-size: 100%;
}
.bottomStyles {
  background-image: url(./assets/img/down.svg);
  background-repeat: no-repeat;
  background-position: right bottom; /*Positioning*/
  background-size: 100%;
}
.fullBottom {
  padding-top: 50px;
  min-height: 100vh;
  background-color: rgba(196, 196, 196, 0.3);
}
.homeInfo {
  color: white;
}
.homeInfo p {
  font-size: 1.2em;
}
.bottomLine {
  background-color: #205fad;
  color: white;
  text-align: center;
  padding: 10px;
}
.bottomLine p {
  margin: 0;
  display: inline;
  text-align: center;
  padding-left: 100px;
  padding-right: 100px;
}
/* drawer start */
.drawer {
  /* display: flex;
  flex-direction: column; */
  background-color: #ffffff;
  width: 20%;
  align-content: center;
  padding-left: 30px;
  padding-top: 10px;
}

.drawerTitle {
  color: #ffbc75;
  margin-top: 20px;
  margin-bottom: 40px;
  font-weight: 600;
}

.drawerButton {
  min-height: 700px;
  width: 30px;
  background-color: #f89426;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
}
.drawerButton svg {
  transition: 0.2s;
  -moz-transition: 0.2s;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
}
.drawerButton svg:hover {
  filter: invert(90%);
}

.drawerInputDateBox {
  align-items: center;
  display: flex;
}
.drawerInputDate {
  width: 80%;
  margin-bottom: 10px;
}
/* .drawerCheckBox {
  width: 80%;
  margin-top: 10px;
  margin-bottom: 10px;
} */

.drawerDropdown {
  width: 80%;
  margin-bottom: 30px;
}
/* drawer end */

/* data */
.dataContent {
  height: 100%;
  width: 100%;
}
.dataContentShift {
  height: 100%;
  width: 80%;
}

.nameBanner {
  height: 30px;
  width: 100%;
  text-align: center;
  color: #157efa;
  font-size: medium;
  font-weight: 600;
  padding: 10px;
  padding-bottom: 5px;
}

/* page */
.page-content {
  padding: 40px;
  padding-bottom: 0px;
  text-align: center;
}

/* footer */
.footer {
  margin-top: 600px;
  height: 150px;
  width: 100%;
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%),
    #fd9426;
}

@media only screen and (max-width: 800px) {
  .drawer {
    width: 50%;
  }
  .MuiTab-wrapper {
    font-size: 12px;
  }
}
/* error page */
.errorPage h1,
.errorPage h2 {
  font-weight: normal;
  color: white;
  font-size: 2em;
  padding: 10px;
  margin: 0;
}
.errorPage h1 {
  display: inline-block;
  background-color: #157efa;
  margin-top: 10vh;
  padding-left: 35vw;
}
.errorPage h2 {
  z-index: 10;
  margin-top: -1vh;
  margin-left: 40vw;
  background-color: #fd9426;
}
/* Privacy page */
.privacy {
  background-color: white;
  position: absolute;
  min-height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  overflow-y: hidden;
}
.privacy div {
  margin: 0 auto;
  max-width: 960px;
  padding: 15px;
}
.privacy h1 {
  display: inline-block;
  color: white;
  background-color: #157efa;
  font-weight: normal;
  font-size: 2em;
  margin: 0;
  padding: 10px;
  padding-left: 35vw;
}
.privacy button {
  margin-top: 10vh;
  text-align: center;
}
.privacy a {
  color: #157efa;
  text-decoration: none;
}
.privacy a:hover {
  color: #0055b6;
}

/*Tabular Page*/
.noDataTable {
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 200px;
}
/*Summary Page*/
.summaryContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.summaryCard {
  width: 45%;
  margin-top: 10px;
}

.summaryCardContent {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

/*Panel*/
.panel {
  background-color: #f5f5f5;
  width: 100%;
}

.headerLeftTop {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  width: 20%;
  height: 60px;
  z-index: 1999;
  text-align: center;
  font-family: Oswald;
  font-size: larger;
  font-weight: bolder;
  padding-top: 18px;
  color: #fff;
  transition: background-color 0.2s;
}
.headerLeftNotTop {
  background-color: #f5f5f5;
  box-shadow: 5px 1px 3px #888888;
  position: fixed;
  top: 0;
  left: 0;
  width: 20%;
  height: 60px;
  z-index: 1999;
  text-align: center;
  font-family: Oswald;
  font-size: larger;
  font-weight: bolder;
  padding-top: 18px;
  color: #205fad;
  transition: background-color 0.2s;
}
/*scroll landing page*/
.scrollspyTop {
  background-color: transparent;
  position: fixed;
  display: flex;
  flex-direction: row;
  top: 0;
  right: 0;
  width: 80%;
  height: 60px;
  z-index: 1999;
  justify-content: space-around;
  transition: background-color 0.2s;
  padding-left: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
ul {
  list-style: none;
}
.scrollspyTop li {
  margin: 10px;
  padding: 10px 0;
}
.scrollspyTop a {
  color: white;
}

.scrollspyNotTop {
  background-color: #f5f5f5;
  box-shadow: 5px 1px 3px #888888;

  position: fixed;
  display: flex;
  flex-direction: row;
  top: 0;
  right: 0;
  width: 80%;
  height: 60px;
  z-index: 1999;
  justify-content: space-around;
  transition: background-color 0.2s;
  padding-left: 0;
}
.scrollspyNotTop li {
  margin: 10px;
  padding: 10px 0;
}
.scrollspyNotTop a {
  color: #205fad;
}

.button {
  height: 40px;
}
.isCurrent {
  font-weight: bold;
}

.isCurrent a,
h2 {
  color: #fbb040;
}

.headerButton {
  width: 40%;
  text-align: right;
}

.homeSection {
  background-color: #000;
  height: 100px;
  width: 300px;
}

html {
  scroll-behavior: smooth;
}

.mailTo {
  height: 50px;
  width: 180px;
  background-color: #205fad;
  border-radius: 5px;
  text-align: center;
  padding-top: 10px;
}

.mailTo:hover {
  background-color: rgb(116, 116, 150);
}

.mailTo a {
  color: #fff;
  display: inline-block;
  position: relative;
  z-index: 1;
  padding: 2em;
  margin: -2em;
}



/*profile*/
.profileItem {
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-content: center;
}

/*headerdropdown*/
.toggle {
  font-weight: bold;
}

/*activity item*/
.activityItem {
  display: flex;
  flex-direction: row;
}
.categoryLable {
  font-weight: bold;
}

/* sidebar */
/* .sidebarItem {
  padding-left: "20px";
  padding-top: "5px";
  padding-bottom: "5px";
  margin-left: "0px";
}

.sidebarTitle {
  padding: "0px";
  margin-top: "10px";
  margin-left: "10px";
} */

/*view activity template*/
.activityTemplateDetail {
  width: 300px;
}

/*create Activitytemplate*/
.regionTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}


/*createCategorytemplate*/
.addCategoryButton {
  /* background-color: #f89426; */
  width: fit-content;
  margin-left: -1em;
}

.categoryItem {
  /* background-color: burlywood; */
  width: fit-content;
}

.categoryItem {
  /* background-color: burlywood; */
  width: fit-content;
}

.sortTable {
  margin-top: 10px;
}

.confirmTreeCategory {
  color: #3692f5;
}

.confirmTreeActivity {
  color: #000000;
}


.collapseTitle{
  display:flex;
  flex-direction:row;
  justify-content:space-between
}